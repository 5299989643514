// This is the controller file where one change reflects to everywhere.

const SITE_CONFIG = {
    apiIP: 'https://www.rawfares.com',
    // apiIP: 'http://localhost',
    siteName: 'rawfares',
    siteID: '16',
    disclaimer: 'The rates, offers, and promotions featured on our website are subject to change and may not always be accurate. There could be occasional errors related to destinations, fares, or deals listed. RawFares is not responsible for any inaccuracies or mistakes concerning travel products and services presented on our site, which include flights, hotels, cruises, vacation packages, and car rentals. We reserve the right to modify, continue, or withdraw any travel promotion, adjust pricing errors, or correct any reservation affected by incorrect pricing or expired offers. If a pricing correction occurs, and subject to availability, you can either maintain your reservation at the updated rate or have it canceled without penalty. Please note that any actions taken regarding flight changes or cancellations are your responsibility.'
  };
  
export default SITE_CONFIG;
  