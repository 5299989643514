// src/components/FAQ.js
import { Box, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Text } from '@chakra-ui/react';

const faqs = [
  { question: 'What is the difference between booking a train and a flight?', answer: 'Train offers scenic routes and a more relaxed atmosphere.' },
  { question: 'Can I bring my own food?', answer: 'Yes, you can bring your own food.' },
];

const FAQ = () => {
  return (
    <Box p={8}>
      <Text fontSize="3xl" fontWeight="bold" mb={4}>Frequently Asked Questions</Text>
      <Accordion allowToggle>
        {faqs.map((faq, index) => (
          <AccordionItem key={index}>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                {faq.question}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              {faq.answer}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default FAQ;
