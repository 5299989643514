import { useParams } from 'react-router-dom';
import RoutesPPC from '../Routes_PPC/RoutesPPC';
import Cities from '../Cities/Cities';

const Flights = () => {
  const { airlineName } = useParams();

  // Conditional rendering based on URL pattern
  if (airlineName.includes('-from-')) {
    return <RoutesPPC airlineName={airlineName} />;
  } else if (airlineName.includes('-to-')) {
    return <Cities airlineName={airlineName} />;
  }

  // Default component or error handling if the pattern doesn't match
  return <div>Page not found</div>;
};

export default Flights;
