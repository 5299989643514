/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import '../resources/css/sitemap.css';
import tnc from '../resources/images/icons/tnc-icon.svg';
import Footer from '../components/Footer/footer';
import MetaTitle from './metaTitle';
import SITE_CONFIG from '../SiteController/SiteController';

export default function Sitemap() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
	const [airlines, setAirlines] = useState({
        pageType: "Airline",
        pageValue: "",
        siteId: siteID,
        url: ""
      });

      const [deals, setDeals] = useState({
        froCity:"",
        toCity:"",
        airline: airlines.pageValue,
        cabinClass:"Y",
        tripType:"2",
        page:"",
        currency:"USD",
        siteId: siteID,
        departDate:"",
        countryName:"US",
        countryCode:"US",
        cityName:""
    });
      const [responseData, setResponseData] = useState(null);
      const navigate = useNavigate();
      useEffect(() => {
        // Use a useEffect to make the API call whenever airlines state changes
        if (airlines.pageValue && airlines.url) {
          handleAirlineClicked();
        }
      }, [airlines]);
    
//       const handleAirlineClicked = async () => {
//         document.body.style.overflowY = 'hidden';
//       const homeBody = document.querySelector('.HomeBody');
// if (homeBody) {
//   const elementsToBlur = homeBody.querySelectorAll(':not(.loader-overlay)');
//   elementsToBlur.forEach((element) => {
//     element.style.filter = 'blur(8px)';
//   });
// }
//       setIsLoading(true)
//       console.log(airlines);
//       console.log(deals)
//         try {
//           const response1 = await axios.post(
//             "http://localhost:9090/api/Airlines-Details",
//             airlines
//           );
      
//           if (response1.status === 200) {
//             setResponseData(response1.data);
//             console.log("Response from the first API:", response1.data);
//             console.log(deals)
//             const response2 = await axios.post("http://localhost:9191/api/Airlines-Routes", deals);
      
//             if (response2.status === 200) {
//               const Data = {
//                 response1: response1.data,
//                 response2: response2.data
//               };
//               console.log("Response from the Second API:", response2.data);
//               const encodedData = encodeURIComponent(JSON.stringify(Data));
//   const navigationPath = airlines.pageValue.length === 2
//     ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
//     : `/flights-to/${airlines.url}-${airlines.pageValue}`;

// //   const newTab = window.open(navigationPath + `?data=${encodedData}`, '_blank');
// //   if (newTab) {
// //     newTab.focus();
// //   } else {
// //     console.error('Failed to open new tab. Please check your browser settings or extensions.');
// //   }

//   //setIsLoading(false);

//               navigate(navigationPath, {
//                 state: Data
//               });
//             document.body.style.overflowY = 'none';
//       const homeBody = document.querySelector('.HomeBody');
// if (homeBody) {
//   const elementsToBlur = homeBody.querySelectorAll(':not(.loader-overlay)');
//   elementsToBlur.forEach((element) => {
//     element.style.filter = 'none';
//   });
// }
//             setIsLoading(false)
//             } else {
//               console.error("Request to the second API failed with status:", response2.status);
//             }
//           } else {
//             console.error("Request to the first API failed with status:", response1.status);
//           }
//         } catch (error) {
//           console.error("Error making one of the API requests:", error);
//         }
//       };

const handleAirlineClicked = async () => {
//     setIsLoading(true);
//     try {
//       const response1 = await axios.post("http://localhost:9090/api/Airlines-Details", airlines);

//       if (response1.status === 200) {
//         setResponseData(response1.data);
//         // Handle response1 data as needed
//         const response2 = await axios.post("http://localhost:9191/api/Airlines-Routes", deals);

//         if (response2.status === 200) {
//           // Handle response2 data as needed
          
//           const Data = {
//             response1: response1.data,
//             response2: response2.data
//           };
//           openNewTabWithData(Data);
//           setIsLoading(false);
//         } else {
//           console.error("Request to the second API failed with status:", response2.status);
//         }
//       } else {
//         console.error("Request to the first API failed with status:", response1.status);
//       }
//     } catch (error) {
//       console.error("Error making one of the API requests:", error);
//     }
//   };

//   const openNewTabWithData = (Data) => {
  const navigationPath =
  airlines.pageValue === "flight"
    ? `/deals/${airlines.url}-${airlines.pageValue}`
    : airlines.pageValue.length === 2
    ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
    : `/flights-to/${airlines.url}-${airlines.pageValue}`;


    const newTab = window.open(navigationPath, '_blank');
    if (newTab) {
        //console.log('Received data in new tab:');
      newTab.onload = function() {
        newTab.postMessage(window.location.origin);
      };
    } else {
      //console.error('Failed to open new tab. Please check your browser settings or extensions.');
    }

    // Navigate to the new page with data passed via state
    //navigate(navigationPath);
  };
      
      
    
      const handleCardClick = (pageType, pageValue, url) => {
        // Set the new values for airlines based on the card clicked
        setAirlines({
          ...airlines,
          pageType,
          pageValue,
          url,
        });
      };

      const openLink = (url) => {
        window.open(url, '_blank', 'width=650,height=550,left=300,top=50');
        };

        const sitemapRef = useRef(null);

        // Handle the scroll when the "View More" button is clicked
        const handleScrollToSitemap = () => {
          if (sitemapRef.current) {
            sitemapRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        };
      
  return (
    <>
    <MetaTitle pageTitle="Explore Sitemap of rawfares.com" pageDescription="Check the sitemap to get the details of flights and other information at rawfares.com" url={`${apiIP}/sitemap`} />
    <div class="container-fluid" >
			
			<div class="row">
			<div class="content_page_title">
			<div class="container">
    <h1><img src={tnc} alt="tnc"/> Sitemap</h1>
			</div>
			</div>
				
		<div class="textBox">
        <div class="container">
          <div class="row">
			<div class="col-sm-12">
              {/* <div class="sitemap-wrap">
                        <h3 class="mt-0">Popular Deals</h3>
						<ul class="site_map_links">
							<li>Winter Travel Deals</li>
							<li>Thanksgiving Flight Deals</li>
							<li>Christmas Flight Deals</li>
							<li>New Year Flight Deals</li>
							<li>Military Travel Deals</li>
							<li>Last Minute Flights</li>
							<li>Student Flights Deals</li>
							<li>Senior Travel Deals</li>
					 </ul>
                     </div> */}
                     <div class="sitemap-wrap">
                        <h3 class="mt-0">Quick Links</h3>
						<ul class="site_map_links">
							<li><Link to='/about'> About Us </Link></li>
							<li><Link to='/contact-us'> Contact Us </Link></li>
              <li><Link to={`${apiIP}/online-check-in`} >Online Check-in</Link></li>
              <li><Link to={`${apiIP}/airline-baggage-fee`} onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>Airline Baggage Fees</Link></li>
              <li><Link to='/post-ticketing-fee'>Post Ticketing Fees</Link></li>
              <li><Link to='/our-services-fee' onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>Our Service Fees</Link></li>
              <li><Link to='/contact-us'>Contact Us</Link></li>
							<li> Privacy Policy </li>
							<li><Link to='/terms-and-condition'> Terms &amp; Conditions </Link></li>
							
					 </ul>
					 
					
					 
                    </div>
                     <div class="sitemap-wrap" ref={sitemapRef}>
                     <h3 class="mt-0">Popular Airlines</h3>
						<ul class="site_map_links" >
							{/* <li><div onClick={(e) => {handleCardClick("Airline", "dl", "delta-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Delta Airlines</div></li> */}
							{/* <li><div onClick={(e) => {handleCardClick("Airline", "wn", "southwest-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Southwest Airlines</div></li> */}
							<li><div onClick={(e) => {handleCardClick("Airline", "ua", "united-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>United Airlines</div></li>
							<li><div onClick={(e) => {handleCardClick("Airline", "as", "alaska-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Alaska Airlines</div></li>
							<li><div onClick={(e) => {handleCardClick("Airline", "aa", "american-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>American Airlines</div></li>
							<li><div onClick={(e) => {handleCardClick("Airline", "b6", "jetblue-airways"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>JetBlue Airways</div></li>
							<li><div onClick={(e) => {handleCardClick("Airline", "ha", "hawaiian-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Hawaiian Airlines</div></li>
							<li><div onClick={(e) => {handleCardClick("Airline", "nk", "spirit-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Spirit Airlines</div></li>
							<li><div onClick={(e) => {handleCardClick("Airline", "f9", "frontier-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Frontier Airlines</div></li>
							<li><div onClick={(e) => {handleCardClick("Airline", "g4", "allegiant-air"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Allegiant Air</div></li>
							<li><div onClick={(e) => {handleCardClick("Airline", "lh", "lufthansa-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Lufthansa Airlines</div></li>
              <li><div onClick={(e) => {handleCardClick("Airline", "sy", "sun-country-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Sun Country Airlines</div></li>
							{/* <li><div onClick={(e) => {handleCardClick("Airline", "3m", "silver-airways"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Silver Airways</div></li> */}
					 </ul>
                    </div>
                    {/* <div class="sitemap-wrap">
                     <h3 class="mt-0">Popular Routes</h3>
						<ul class="site_map_links">
							<li>Los Angeles to New York</li>
							<li>New York to Miami</li>
							<li>Chicago to New York</li>
							<li>New York to London</li>
							<li>New York to Paris</li>
					 </ul>
                    </div> */}

                    <div class="sitemap-wrap">
                     <h3 class="mt-0">Popular Domestic Flights</h3>
						<ul class="site_map_links">
							{/* <li><div onClick={(e) => {handleCardClick("City", "las", "las-vegas"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Flights to Las Vegas</div></li> */}
							{/* <li><div onClick={(e) => {handleCardClick("City", "mco", "orlando"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Flights to Orlando</div></li> */}
              <li><div onClick={(e) => handleCardClick("City", "nyc", "new-york")} style={{cursor: 'pointer'}}>Flights to New York</div></li>
							<li><div onClick={(e) => {handleCardClick("City", "lax", "los-angeles"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Flights to Los Angeles</div></li>
							<li><div onClick={(e) => {handleCardClick("City", "mia", "miami"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Flights to Miami</div></li>
							<li><div onClick={(e) => {handleCardClick("City", "atl", "atlanta"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Flights to Atlanta</div></li>
              <li><div onClick={(e) => {handleCardClick("City", "ord", "chicago"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Flights to Chicago</div></li>
              <li><div onClick={(e) => {handleCardClick("City", "was", "washington"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Flights to Washington</div></li>
              <li><div onClick={(e) => {handleCardClick("City", "sfo", "san-francisco"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Flights to San Francisco</div></li>
              <li><div onClick={(e) => {handleCardClick("City", "abq", "albuquerque"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Flights to Albuquerque</div></li>
					 </ul>
                    </div>

                    <div class="sitemap-wrap">
                     <h3 class="mt-0">Popular International Flights</h3>
						<ul class="site_map_links">
							<li><div onClick={(e) => {handleCardClick("City", "ams", "amsterdam") /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Flights to Amsterdam</div></li>
							<li><div onClick={(e) => {handleCardClick("City", "mex", "mexico-city"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Flights to Mexico City</div></li>
              
              
							{/* <li><div onClick={(e) => {handleCardClick("City", "cdg", "paris"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Flights to Paris</div></li>
							<li><div onClick={(e) => {handleCardClick("City", "sjc", "san-jose"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Flights to San Jose</div></li>
							<li><div onClick={(e) => {handleCardClick("City", "syd", "sydney"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Flights to Sydney</div></li> */}
					 </ul>
                    </div>

                    <div class="sitemap-wrap">
                     <h3 class="mt-0">Popular Deals</h3>
						<ul class="site_map_links">
							<li><div onClick={(e) => {handleCardClick("deals", "flight", "military") /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Military Flight</div></li>
							<li><div onClick={(e) => {handleCardClick("deals", "flight", "summer"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Summer Flight</div></li>
              <li><div onClick={(e) => {handleCardClick("deals", "flight", "student"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Student Flight</div></li>
              <li><div onClick={(e) => {handleCardClick("deals", "flight", "senior"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Senior Flight</div></li>
              <li><div onClick={(e) => {handleCardClick("deals", "flight", "honeymoon"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Honeymoon Flight</div></li>
              <li><div onClick={(e) => {handleCardClick("deals", "flight", "last-minute"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Last Minute Flight</div></li>
              <li><div onClick={(e) => {handleCardClick("deals", "flight", "business-class"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Business Class Flight</div></li>
              <li><div onClick={(e) => {handleCardClick("deals", "flight", "lgbtq"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>LGBTQ Flight</div></li>
              <li><div onClick={(e) => {handleCardClick("deals", "flight", "domestic"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Domestic Flight</div></li>
              <li><div onClick={(e) => {handleCardClick("deals", "flight", "international"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>International Flight</div></li>
              
							{/* <li><div onClick={(e) => {handleCardClick("City", "cdg", "paris"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Flights to Paris</div></li>
							<li><div onClick={(e) => {handleCardClick("City", "sjc", "san-jose"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Flights to San Jose</div></li>
							<li><div onClick={(e) => {handleCardClick("City", "syd", "sydney"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Flights to Sydney</div></li> */}
					 </ul>
                    </div>
					
					
            </div>	
          </div>
        </div>
      </div>
				
			
				
		      </div>
				
			</div> 
            <Footer/>
            </>
  )
}
