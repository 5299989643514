// src/components/PopularRoutes.js
import { useState } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, Modal, ModalOverlay,
  ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  Input, useDisclosure, FormControl, FormLabel, Spinner
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';

const routes = [
  { name: 'Northwest Corridor', duration: '5 hours', location: 'Seattle - Portland' },
  { name: 'Pacific Surfliner', duration: '8 hours', location: 'San Diego - LA' },
];

const PopularRoutes = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleBookClick = (route) => {
    setSelectedRoute(route);
    onOpen();
  };

  const handleSubmit = () => {
    if (!email || !contactNumber) {
      toast({
        title: 'Validation Error',
        description: 'Please fill in all required fields.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true); 
    const data = {
      trainName: selectedRoute.name,
      duration: selectedRoute.duration,
      location: selectedRoute.location,
      email,
      contactNumber,
    };
    
    // Send this data to backend to trigger the email
    fetch('https://www.rawfares.com:8303/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      onClose(); // Close the modal after successful submission
    })
    .catch((error) => {
      console.error('Error:', error);
    })
    .finally(() => {
      setIsLoading(false); // Stop spinner after request completes
    });
  };

  return (
    <Box p={8}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Train Name</Th>
            <Th>Duration</Th>
            <Th>Location</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {routes.map((route, index) => (
            <Tr key={index}>
              <Td>{route.name}</Td>
              <Td>{route.duration}</Td>
              <Td>{route.location}</Td>
              <Td>
                <Button colorScheme="blue" onClick={() => handleBookClick(route)}>Book</Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Modal for booking form */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Book Your Trip</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isDisabled>
              <FormLabel>Train Name</FormLabel>
              <Input value={selectedRoute?.name || ''} readOnly />
            </FormControl>

            <FormControl mt={4} isDisabled>
              <FormLabel>Duration</FormLabel>
              <Input value={selectedRoute?.duration || ''} readOnly />
            </FormControl>

            <FormControl mt={4} isDisabled>
              <FormLabel>Location</FormLabel>
              <Input value={selectedRoute?.location || ''} readOnly />
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel>Contact Number</FormLabel>
              <Input
                type="tel"
                placeholder="Enter your contact number"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Submit'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PopularRoutes;
