// src/components/TrainServiceSection.js
import { Box, SimpleGrid, Text, Icon, Image, Flex, VStack } from '@chakra-ui/react';
import { FaHandHolding, FaMapMarkedAlt, FaSuitcaseRolling } from 'react-icons/fa';
import train from '../../../resources/images/amtrak/trianbnr.webp'

const services = [
  {
    title: 'Fast And Easy Rail Bookings',
    description: 'We offer on the call ticket reservations for all! Contact us today and avail special deals.',
    icon: FaHandHolding,
  },
  {
    title: 'Travel All Across North America',
    description: 'With over 500 destinations, FastWayLine offers the best Amtrak Deals for all your needs.',
    icon: FaMapMarkedAlt,
  },
  {
    title: 'Fast Track Your Trip',
    description: 'Enjoy priority access and skip the long queue lines at the station. Ensuring you a seamless journey.',
    icon: FaSuitcaseRolling,
  },
];

const TrainServiceSection = () => {
  return (
    <Box py={12} bg="gray.100">
      <Flex maxW="1200px" mx="auto" align="center" justify="space-between" flexDirection={['column', 'column', 'row']}>
        {/* Service Cards */}
        <VStack spacing={6} align="stretch" w={['100%', '100%', '50%']}>
          <Text fontSize="3xl" fontWeight="bold" mb={6} lineHeight="1.3">
            Every station is a stop but it does not mean it is the end of the line
          </Text>
          {services.map((service, index) => (
            <Flex
              key={index}
              p={5}
              shadow="md"
              borderWidth="1px"
              rounded="md"
              bg="white"
              align="center"
            >
              <Icon as={service.icon} boxSize={10} color="orange.400" mr={4} />
              <Box>
                <Text fontWeight="bold" fontSize="lg">
                  {service.title}
                </Text>
                <Text>{service.description}</Text>
              </Box>
            </Flex>
          ))}
        </VStack>

        {/* Train Image */}
        <Box w={['100%', '100%', '45%']} mt={[8, 8, 0]}>
          <Image
            src={train} // Replace with the actual image URL
            alt="Train"
            rounded="md"
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default TrainServiceSection;
