import React from 'react';
import Header from './components/Header';
import JourneyHighlights from './components/JourneyHighlights';
import PopularRoutes from './components/PopularRoutes';
import ExploreAmerica from './components/ExploreAmerica';
import FAQ from './components/FAQ';
import Footer from '../../components/Footer/footer';
import TrainServiceSection from './components/TrainServiceSection';
//import Footer from './components/Footer';

function Amtrak() {
  return (
    <>
      <Header />
      <JourneyHighlights />
      <TrainServiceSection />
      <PopularRoutes />
      <ExploreAmerica />
      <FAQ />
      <Footer />
    </>
  );
}

export default Amtrak;
