// src/components/JourneyHighlights.js
import { Box, SimpleGrid, Text, Icon, Heading } from '@chakra-ui/react';
import { FaTrain, FaMountain, FaConciergeBell } from 'react-icons/fa';

const highlights = [
  { title: 'Comfort & Luxury', description: 'Premium class experience onboard. Amtrak offers a variety of seating and sleeping options for comfort and luxury,including: Bedrooms, Acela First Class, Coach Class, Business Class', icon: FaConciergeBell },
  { title: 'Breathtaking Views', description: 'See America’s best landscapes. Amtrak offers many scenic train routes that provide breathtaking views, including: Empire Builder, Pacific Surfliner, California Zephyr, Coast Starlight. ', icon: FaMountain },
  { title: 'Efficient Service', description: 'Top-tier service to enhance your journey. Amtrak is a passenger rail operator that offers efficient service in several ways, including: Energy efficiency, Customer service, Infrastructure, Security, Amtrak Airo.', icon: FaTrain },
];

const stepsToBook = [
  'Go to the Rawfares Website: Open your browser and navigate to the Rawfares homepage.',
  'Search for Your Route: Enter your departure and arrival stations. Select your travel dates. Click on the search button.',
  'Choose Your Train: Look through the list of available trains. You can see departure times, durations, and prices.',
  'Select Your Ticket: Choose the train that works best for you and click to select it.',
  'Enter Passenger Details: Fill in the required information for all passengers, including names and any special requests.',
  'Payment Information: Enter your payment details to complete the booking.',
  'Confirmation: Once your payment is processed, you should receive a confirmation email with your ticket information.'
];

const trainClasses = [
  { name: 'Economy Class', description: 'Basic seating with standard amenities. It\'s usually the most affordable option.' },
  { name: 'Business Class', description: 'More spacious seating, additional legroom, and sometimes complimentary meals or drinks.' },
  { name: 'First Class', description: 'Premium seating with luxurious amenities, more privacy, and often enhanced dining options.' },
  { name: 'Sleeper Class', description: 'Available on long-distance trains, offering sleeping accommodations like private cabins or sleeper cars.' },
  { name: 'Coach Class', description: 'Similar to economy but may have fewer amenities, typically used for shorter trips.' },
];

const JourneyHighlights = () => {
  return (
    <>
    <Box p={8}>
      <SimpleGrid columns={[1, 2, 3]} spacing={10}>
        {highlights.map((highlight, index) => (
          <Box key={index} textAlign="center">
            <Icon as={highlight.icon} boxSize={12} mb={4} />
            <Text fontSize="2xl" fontWeight="bold">
              {highlight.title}
            </Text>
            <Text mt={2}>{highlight.description}</Text>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
    <Box p={8}>
    <Box p={8} bg="gray.50" borderRadius="md" boxShadow="lg" mb={10}>
        <Heading fontSize="2xl" mb={4} color="teal.600">
          How to Book Train Tickets with Rawfares
        </Heading>
        <ol style={{ paddingLeft: "1.5em" }}>
          {stepsToBook.map((step, index) => (
            <li key={index}>
              <Text mb={2} color="gray.700">
                {index + 1}. {step}
              </Text>
            </li>
          ))}
        </ol>
      </Box>

      <Box p={8} bg="gray.50" borderRadius="md" boxShadow="lg">
        <Heading fontSize="2xl" mb={4} color="teal.600">
          Train Classes Available
        </Heading>
        <SimpleGrid columns={[1, 2, 3]} spacing={6}>
          {trainClasses.map((trainClass, index) => (
            <Box key={index} p={4} borderWidth={1} borderRadius="md" bg="white">
              <Heading fontSize="lg" mb={2} color="gray.800">
                {trainClass.name}
              </Heading>
              <Text color="gray.600">{trainClass.description}</Text>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
    </>
  );
};

export default JourneyHighlights;
