// src/components/ExploreAmerica.js
import { Box, SimpleGrid, Image, Text } from '@chakra-ui/react';
import nyc from '../../../resources/images/amtrak/New-york.webp';
import cig from '../../../resources/images/amtrak/chicago.webp';
import sfo from '../../../resources/images/amtrak/San-Francisco.webp'

const places = [
  { name: 'New York', img: `${nyc}` },
  { name: 'Chicago', img: `${cig}` },
  { name: 'San Francisco', img: `${sfo}` },
];

const ExploreAmerica = () => {
  return (
    <Box p={8}>
      <Text fontSize="3xl" fontWeight="bold" mb={4}>Explore the Americas</Text>
      <SimpleGrid columns={[1, 2, 3]} spacing={8}>
        {places.map((place, index) => (
          <Box key={index} textAlign="center">
            <Image src={place.img} alt={place.name} borderRadius="md" mb={4} />
            <Text fontSize="xl">{place.name}</Text>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default ExploreAmerica;
