import { useState } from 'react';
import { 
  Box, Button, Heading, Text, Select, Modal, ModalOverlay, ModalContent, ModalHeader, 
  ModalFooter, ModalBody, ModalCloseButton, Input, useDisclosure, FormControl, 
  FormLabel, Spinner 
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import banner from '../../../resources/images/amtrak/banner.webp';

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [trainName, setTrainName] = useState(''); 
  const [duration, setDuration] = useState(''); // Updated: duration now uses Select
  const [location, setLocation] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleBookClick = () => {
    onOpen();
  };

  const handleSubmit = () => {
    if (!trainName || !duration || !location || !email || !contactNumber) {
      toast({
        title: 'Validation Error',
        description: 'Please fill in all required fields.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true); 

    const data = {
      trainName,
      duration,
      location,
      email,
      contactNumber,
    };

    // Send this data to backend to trigger the email
    fetch('https://www.rawfares.com:8303/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        onClose(); // Close the modal after successful submission
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setIsLoading(false); // Stop spinner after request completes
      });
  };

  return (
    <Box
      backgroundImage={banner}
      backgroundSize="cover"
      backgroundPosition="center"
      height="50vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      color="white"
      textAlign="center"
    >
      <Heading fontSize="5xl">Traverse through American Rail Tracks</Heading>
      <Text fontSize="xl" mt={4}>
        Experience the joy of railroad riding across the country.
      </Text>
      <Button mt={8} colorScheme="blue" size="lg" onClick={handleBookClick}>
        Book Your Trip
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Book Your Trip</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>Train Name</FormLabel>
              <Input
                type="text"
                placeholder="Enter the train name"
                value={trainName}
                onChange={(e) => setTrainName(e.target.value)} // Set train name
              />
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel>Duration</FormLabel>
              <Select
                placeholder="Select duration in hours"
                value={duration}
                onChange={(e) => setDuration(e.target.value)} // Set duration
              >
                {[...Array(13)].map((_, i) => (
                  <option key={i + 3} value={i + 3}>
                    {i + 3} hours
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel>Location</FormLabel>
              <Input
                type="text"
                placeholder="Enter your location"
                value={location}
                onChange={(e) => setLocation(e.target.value)} // Set location
              />
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Set email
              />
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel>Contact Number</FormLabel>
              <Input
                type="tel"
                placeholder="Enter your contact number"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)} // Set contact number
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Submit'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Header;
